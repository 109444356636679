import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "react-bootstrap/Image";
import { uuid } from 'uuidv4';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'


const FileFormats = ((props) => {
    const { allListsYaml } = useStaticQuery(
        graphql`
            {
                allListsYaml {
                    edges {
                        node {
                            id
                            slug
                            title
                            text
                            image
                            link
                            items {
                                title
                                text
                                image
                            }
                        }
                    }
                }
            }
        `
    )

    return (
        allListsYaml.edges.map((data) => {
            const list = data.node;
            if (list.slug !== 'file-formats') {
                return ''
            }

            return(
                <Jumbotron key={`${list.slug}-${uuid()}`} style={{backgroundColor: props.bgColor}} className={'mb-0'}>
                    <Container key={`${list.slug}-${uuid()}`} className={'file-formats mt-4 mb-0'}>
                        <Row className={'text-center mb-5'}>
                            <Col>
                                <h2>{list.title}</h2>
                            </Col>
                        </Row>
                        <Row key={`${list.slug}-${uuid()}`}>
                            { ! list.items || list.items.length === 0 ? '' :
                                list.items.map((item) => {
                                    return(
                                        <Col key={`item-${uuid()}`} md={4} className={'file-format mb-4'}>
                                            <Row key={`${list.slug}-${uuid()}`}>
                                                <Col xs={3}>
                                                    <Image src={item.image} alt={item.title} />
                                                </Col>
                                                <Col xs={9}>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.text}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </Jumbotron>
            )
        })
    )
})

export default FileFormats;
