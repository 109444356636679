// React & Gatsby
import React from "react"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import Image from "react-bootstrap/Image";

// Custom
import BlankPage from "../components/Content/BlankPage";
import PriceBlock from "../components/Content/PriceBlock/index";
import FileFormats from "../components/Content/FileFormats";


const BuyNowPage = ((props) => {

    const { allMarkdownRemark } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark(filter: {frontmatter: {slug :{eq: "buy-now"}}}) {
                    edges {
                        node {
                            id
                            frontmatter {
                                slug
                                title
                                alt
                                date
                                image
                            }
                            html
                            rawMarkdownBody
                            excerpt
                        }
                    }
                }
            }
        `
    )

    let content = {
        html : '',
        frontmatter : {
            slug   : '',
            title  : '',
            alt    : '',
            date   : '',
            image  : ''
        }
    };

    if (allMarkdownRemark.edges && allMarkdownRemark.edges.length) {
        content = allMarkdownRemark.edges[0].node
    }

    return (
        <BlankPage>
            <Container className={'justify-content-center mt-4 mb-4 pl-4 pr-4'}>
                <Row className={'justify-content-lg-center mb-5'}>
                    <Col lg={'6'}>
                        <Image
                            style={{width: '100%'}}
                            src={content.frontmatter.image}
                            alt={content.frontmatter.alt}
                        />
                    </Col>
                    <Col lg={'6'} className={'pt-5'}>
                        <h1>{content.frontmatter.title}</h1>
                        <div dangerouslySetInnerHTML={{__html: content.html }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PriceBlock
                            bgColor={'#00c872'}
                            name={'Starter'}
                            features={[
                                '30 Avatars in multiple formats',
                                'SVG, AI, Affinity Designer, and IconJar',
                                'Figma, Adobe XD, Sketch, and InVision Studio'
                            ]}
                            price={'FREE'}
                            licenseName={'Standard License'}
                            licenseLink={'/standard-license'}
                            buttonLink={'#'}
                            buttonText={'Download Now'}
                        />
                    </Col>
                    <Col>
                        <PriceBlock
                            bgColor={'#00bbec'}
                            name={'Ultimate Pack'}
                            marker={'Most Popular'}
                            features={[
                                '895 Avatars in SVG format',
                                'SVG, AI, Affinity Designer, and IconJar',
                                'Figma, Adobe XD, Sketch, InVision Studio'
                            ]}
                            price={'$99'}
                            licenseName={'Standard License'}
                            licenseLink={'/standard-license'}
                            buttonLink={'#'}
                            buttonText={'Buy Now - $99'}
                        />
                    </Col>
                    <Col>
                        <PriceBlock
                            bgColor={'#9d62b8'}
                            name={'Essential Pack'}
                            features={[
                                '250 Avatars',
                                'SVG, AI, Affinity Designer, and IconJar',
                                'Figma, Adobe XD, Sketch, InVision Studio'
                            ]}
                            price={'$49'}
                            licenseName={'Standard License'}
                            licenseLink={'/standard-license'}
                            buttonLink={'#'}
                            buttonText={'Buy Now - $49'}
                        />
                    </Col>
                </Row>
            </Container>
            <FileFormats bgColor={'#fff'} />
        </BlankPage>
    )
})

export default BuyNowPage;
