// React & Gatsby
import React, {Component} from "react"
import {uuid} from "uuidv4";

// Custom
import PillButton from "../../PillButton"

// Style & Content
import "./styles.scss";



class PriceBlock extends Component {
    render() {
        let marker = '';
        if (this.props.marker) {
            marker = <div className="marker">{this.props.marker}</div>
        }
        return (
            <div className="price-block" style={{
                borderColor : this.props.bgColor
            }}>
                <div className="inner">
                    {marker}
                    <div className="header" style={{
                        backgroundColor : this.props.bgColor
                    }}>
                        <h6>{this.props.name}</h6>
                        <div className="divide"></div>
                        <p className="price">{this.props.price}</p>
                    </div>
                    <div className="features">
                        {this.props.features.map((feature) => {
                            return(
                                <div key={`price-block-${uuid()}`} className="feature">
                                    {feature}
                                </div>
                            )
                        })}
                        <div className="feature license">
                            <a href={this.props.licenseLink}>{this.props.licenseName}</a>
                        </div>
                    </div>
                    <div className="buy-button">
                        <PillButton
                            link={this.props.buttonLink}
                            text={this.props.buttonText}
                            target={'_blank'}
                            className={'pill-button pl-4 pr-4'}
                            style={{
                                textTransform   : 'uppercase',
                                backgroundColor : this.props.bgColor,
                                border          : 'none',
                                borderColor     : 'none',
                                color           : '#fff',
                                fontWeight      : '700',
                                letterSpacing   : '1px'
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PriceBlock;
